.form-container {
  width: 80%;
  margin: 70px auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  border-radius: 10px;
  height: min-content;
}
.form-container form{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.reviewAskYesDiv label,
.reviewAskNoDiv label {
  font-size: 20px;
  font-weight: 500;
  margin-left: 7px;
}
.reviewAskNoDiv {
  position: absolute;
  left: 70px;
}
.inputConditionGroup {
  margin-top: 15px;
  position: relative;
  display: flex;
    flex-direction: column;
    gap: 10px;
}

.reviewAskDiv {
  align-items: flex-start;
  margin-top: 10px;
  position: relative;
}

.reviewGivenDiv,
.reasonDiv,
.reviewGivenCele {
  display: none;
}
.reviewGivenDiv {
  position: relative;
  margin-top: 15px;
}
.reviewGivenDiv h4 {
  margin-bottom: 10px;
}
.reviewGivenNo {
  position: absolute;
  left: 70px;
  width: max-content;
}
.reasonDiv {
  min-width: 400px;
  margin-top: 15px;
  margin-left: -70px;
}

.reasonDiv input {
  width: 100%;
}

.reviewGivenCele h5 {
  font-size: 18px;
  font-weight: 700;
  width: max-content;
  color: rgb(127, 229, 255);
  margin-top: 15px;
}
#reviewAskY:checked ~ .reviewGivenDiv {
  display: block;
}

#reviewAskN:checked ~ .reasonDiv {
  display: block;
}
#reviewGivenN:checked ~ .reasonDiv {
  display: block;
}
#reviewGivenY:checked ~ .reviewGivenCele {
  display: block;
}

.submitBtnDiv {
  margin-top: 70px;
  text-align: center;
}
.selectApp {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.askAgainReview {
  margin-top: 80px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.askAgainReview input {
  width: 20px;
  cursor: pointer;
}
.askAgainReview label {
  font-size: 17px;
}
.selectApp label {
  font-size: 18px;
  font-weight: 700;
}
.selectApp select,
.clientType select {
  width: 190px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 7px 15px;
}
.clientType {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.noOfcalls {
  display: none;
}
.addClient .selectApp {
  margin-top: 10px;
}
.askAgainReview.storeDev{
  margin-top: 0;
}

.addClient .askAgainReview.storeDev{
  margin-top: 80px;
}

input[type="checkbox"] {
  width: 20px;
  cursor: pointer;
}

.revReason{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.revReason select{
  min-width: 190px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 7px 15px;
    max-width: min-content;
}