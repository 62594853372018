.sidebar {
  background-color: var(--dashbg);
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
}
.sidebar nav {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: left;
  align-items: flex-start;
  position: sticky;
  top: 9%;
  margin-top: 70px;
}

.sidebar nav a {
  color: var(--textPrimary);
  background-color: var(--btnBg);
  font-size: 1.2rem;
  padding: 10px 5px;
  margin-top: 8px;
  width: 200px;
  border-radius: 10px;
  text-align: center;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  cursor: pointer;
  text-decoration: none;
}
.sidebar nav a:hover {
  background-color: var(--btnHover);
  color: var(--textSecondary);
}
