.summary-conatiner h2 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin: 30px 0;
  text-transform: uppercase;
}
.cards-list {
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card {
  margin: 30px auto;
  width: 200px;
  height: 200px;
  border-radius: 40px;
  box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25),
    -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition: 0.4s;
}

.card .card_image {
  width: inherit;
  height: inherit;
  border-radius: 40px;
}

.card .card_image img {
  width: inherit;
  height: inherit;
  border-radius: 40px;
  object-fit: cover;
}

.card .card_title {
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 30px;
  margin-top: -140px;
  height: 40px;
}
.card .card_title h3 {
  color: #09080a;
  font-size: 48px;
  font-weight: 700;
  margin-top: 10px;
}
.card .card_title p {
  color: #725d87;
  font-size: 18px;
  font-weight: 700;
}
.card:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25),
    -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
}

.title-white {
  color: white;
}

.title-black {
  color: black;
}

@media all and (max-width: 500px) {
  .card-list {
    flex-direction: column;
  }
}

.search-summary-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.search-summary-container .searchFieldSummary {
  width: 30%;
  margin-right: 10px;
  min-width: 300px;
}
.resetBtn {
  height: 45px;
  margin: 30px 0;
}
.chooseApp,
.chooseMonthYear {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.chooseApp select {
  min-width: 150px;
  min-height: 43px;
  border-radius: 10px;
  text-align: center;
  font-size: 15px;
}
.showTotalSummary {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.mainSummaruShowDiv {
  display: flex;
  gap: 10px;
}

.updateSummaryDiv form{
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 17px;
}


.showTotal{
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;
  margin-left: 30px;
}

.showTotal>div{
  min-width: 300px;
}

.showTotal div h3{
  padding: 10px 0;
  text-align: center;
  background-color: #ccc;
}

.showTotal div div p{
  text-align: center;
  padding: 10px 0;
  font-weight: 500;
  font-size: 17px;
  background-color: #f3f3f3;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
}