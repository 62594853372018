.loadingDiv {
  margin-top: 100px;
  background-color: #f3f7f779;
  color: #fff;
  text-align: center;
  width: 100%;
  height: 100%;
  position: fixed;
}

#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 7px solid rgb(0 231 90 / 90%);
  border-radius: 50%;
  border-top-color: rgb(59 48 4);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  position: absolute;
  left: 35%;
  top: 30%;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
