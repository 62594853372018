.content-container {
  padding: 0 30px;
}

.tableParent {
  width: 100%;
}

.table {
  margin: 0 auto;
}
.table thead tr th {
  padding: 10px;
  background-color: var(--bgdDark);
  color: var(--textPrimary);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.table tbody tr td {
  padding: 10px;
  background-color: var(--tableBodyBg);
  color: #000;
  text-align: center;
  font-size: 17px;
  font-weight: 550;
}
.search-container {
  font-family: "Roboto", Tahoma, Arial, sans-serif;
  line-height: 1.5;
  font-size: 13px;
  margin-top: 15px;
  width: 100%;
}

.searchForm {
  outline: 0;
  float: left;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.searchForm > .textbox {
  outline: 0;
  height: 42px;
  width: 244px;
  line-height: 42px;
  padding: 0 16px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #212121;
  border: 0;
  float: left;
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.searchForm > .textbox:focus {
  outline: 0;
  background-color: #fff;
}

.searchForm > .button {
  outline: 0;
  background: none;
  background-color: rgba(38, 50, 56, 0.8);
  float: left;
  height: 42px;
  width: 42px;
  text-align: center;
  line-height: 42px;
  border: 0;
  color: #fff;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 16px;
  text-rendering: auto;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: background-color 0.4s ease;
  transition: background-color 0.4s ease;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.searchForm > .button:hover {
  background-color: rgba(0, 150, 136, 0.8);
}

.searchResutDiv {
  margin: 10px 0;
}

.wantToaddP {
  color: rgb(214, 28, 28);
  font-weight: 500;
  font-size: 17px;
  margin: 5px 0;
}
.wantToaddP span {
  color: rgb(0, 0, 0);
  font-size: 19px;
  font-weight: 700;
}

.searchFoundStoreDiv h4 {
  font-size: 20px;
  color: #212121;
  font-weight: 500;
}

.searchFoundStoreDiv h4 span {
  font-size: 22px;
  font-weight: 700;
  color: black;
}

@import url(https://fonts.googleapis.com/css?family=Roboto:100);

.selectAppSearch {
  margin: 20px 0;
}
.selectAppSearch input {
  margin-right: 2px;
}
.selectAppSearch label {
  font-size: 22px;
  margin-right: 10px;
}
.selectAppSearch label:nth-child(1) {
  font-size: 25px;
  font-weight: 700;
}

.selctAppWarning {
  font-size: 15px;
  color: red;
  margin: 5px 0;
  font-weight: 600;
}

.appImg img {
  max-width: 80px;
  max-height: 60px;
}
.pagination {
  margin: 20px 0;
}
.pageNum button {
  min-width: 30px;
  height: 25px;
  border-radius: 5px;
  padding: 0;
  font-size: 15px;
  margin-right: 5px;
}
.pageSize select {
  width: 80px;
  height: 30px;
}
.pagination .selected {
  background-color: gold;
}

.summaryShowDashboardMainDiv h2 {
  text-transform: uppercase;
  margin: 10px 0;
  text-align: center;
  font-weight: 600;
}
.summaryShowDashboard {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.summaryShowDashboard p {
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  border-radius: 10px;
  font-size: 35px;
  font-weight: 700;
  color: #39b900;
}
.summaryShowDashboard p span {
  font-size: 17px;
  color: #212121;
}
.icon {
  max-width: 40px;
  cursor: pointer;
}
.clBtnUpdateDel > div {
  display: inline-block;
  margin-right: 9px;
}
.increaseCall {
  display: flex;
  align-items: center;
  gap: 12px;
}
.increaseCall input {
  width: 20px;
  cursor: pointer;
}
.pagination{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pageNum{
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.pageNum button {
  border-radius: 5px;
  font-size: 15px;
  height: 25px;
  margin-right: 5px;
  padding: 0 10px;
}

.jumPage{
  display: flex;
  gap: 5px;
  align-items: center;
}

.jumPage>input{
  max-height: 35px;
  cursor: pointer;
}
.pageSize{
  display: flex;
  align-items: center;
  gap: 7px;
}
.table tbody tr td.clrevAsk{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.table tbody tr.tryBg td.clrevAsk {
  background-color: #ffe599;
}
.table tbody tr.dangerBg td.clrevAsk {
  background-color: red;
  color: #fff;
}
.table tbody tr.riskyBg td.clrevAsk {
  background-color: #f90;
}
.table tbody tr.safeBg td.clrevAsk {
  background-color: #0f0;
}