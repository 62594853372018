.upDelIcon span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}
.upDelIcon > div {
  display: inline-block;
  margin-right: 10px;
}
